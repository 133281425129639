import axios from "axios";
import {Toaster} from "@findyourcanopy/canopy-ui";

import history from "utils/history";
import Canceller from "utils/cancelRequest";
import getTranslations from "utils/locales";
import {abortRequestError} from "constants/httpErrors";
import analytics from "services/analytics";
import {backendErrors} from "constants/branchSelector";

// eslint-disable-next-line import/no-cycle
import {refreshAuthenticationToken} from "./auth";
import {formatErrorFromBackend, branchAccessDenied, sessionExpired} from "./helpers";

const locales = getTranslations();

const baseURL = process.env.REACT_APP_BASE_URL;

let ghostId = null;
let token = null;
let refreshToken = null;

const amazonRequestCacheOptions = "no-cache";
const publicRentPassportPart = "public-rent-passport";

export const setToken = value => {
  token = value;
};

export const unsetToken = () => {
  token = null;
};

export const setGhostId = value => {
  ghostId = value;
};

export const unsetGhostId = () => {
  ghostId = null;
};

export const setRefreshToken = value => {
  refreshToken = value;
};

export const unsetRefreshToken = () => {
  refreshToken = null;
};

const getToken = async () => token;

const getGhostId = async () => ghostId;

const getRefreshToken = async () => refreshToken;

export const http = axios.create({
  baseURL,
  headers: {
    "Accept-Version": "2.202.0",
    "Content-Type": "application/json; charset=utf-8",
    "x-src": "cloudfront-hq",
  },
});

const throwResponseError = err => {
  throw err;
}; // eslint-disable-line no-throw-literal

const getMainMessage = message => {
  if (message && message.length > 0) {
    // we want meaningful data to be shown
    return `${formatErrorFromBackend(message)}`;
  }

  return locales.errors.somethingWentWrong;
};

const formatSubErrors = errors => {
  if (errors.length === 0) {
    return "";
  }

  return errors.map(err => formatErrorFromBackend(err.message)).join("");
};

http.interceptors.request.use(
  async config => {
    const authToken = await getToken();
    const storedGhostId = await getGhostId();

    const {CancelToken} = axios;
    const source = CancelToken.source();

    Canceller.setCancelToken(source.cancel);

    // eslint-disable-next-line no-param-reassign
    config.cancelToken = source.token;

    if (!config.url.includes(publicRentPassportPart)) {
      if (authToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      if (storedGhostId) {
        // eslint-disable-next-line no-param-reassign
        config.headers["ghost-mode"] = storedGhostId;
      }
    }

    return config;
  },
  error => Promise.reject(error),
);

http.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const {
      data: {data: responseData},
    } = response;

    if (responseData) {
      // /ugly case where success: true, data: {success: false} while login
      if (responseData.message && responseData.code) {
        return throwResponseError({
          ...responseData,
          message: `${formatErrorFromBackend(responseData.message)}`,
          status: 0,
        });
      }
    }

    return responseData;
  },
  async error => {
    const originalRequest = error.config;

    if (error.request.status === 504) {
      analytics.logEvent("failedToLoadData", {pathname: history.location.pathname});
    }

    if (
      // eslint-disable-next-line no-underscore-dangle
      !originalRequest._retry &&
      error.request.status === 401 &&
      !originalRequest.url.includes("auth") &&
      !originalRequest.url.includes(publicRentPassportPart)
    ) {
      analytics.logEvent("warningSessionExpired");
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;

      let response;

      try {
        const authRefreshToken = await getRefreshToken();

        response = await refreshAuthenticationToken({refreshToken: authRefreshToken});
      } catch (err) {
        analytics.logEvent("warningSessionExpired");

        return sessionExpired();
      }

      try {
        localStorage.setItem("loginData", JSON.stringify(response));
        if (response.ghostId) {
          setGhostId(response.ghostId);
        }
        setToken(response.token);
        setRefreshToken(response.refreshToken);

        if (ghostId) {
          axios.defaults.headers.common["ghost-mode"] = ghostId;
          originalRequest.headers["ghost-mode"] = ghostId;
        }

        axios.defaults.headers.common.Authorization = `Bearer ${response.token}`;
        originalRequest.headers.Authorization = `Bearer ${response.token}`;

        const data = await axios(originalRequest);

        const {
          data: {data: responseData},
        } = data;

        return responseData;
      } catch (err) {
        return throwResponseError({message: locales.errors.somethingWentWrong, status: err.status});
      }
    }

    if (error.request.status === 401 && originalRequest.url.includes("public-rent-passport")) {
      localStorage.removeItem("publicRentPassportAuthData");
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      const {
        data,
        data: {error: backendError},
        status,
      } = error.response;

      if (status === 403 && backendError.type === backendErrors.permissionDeniedToBranch) {
        analytics.logErrorWithLevel("ToastOverlay", {
          code: 403,
          message: backendError.message,
        });

        Toaster.toastConfig.showError({title: backendError.message});

        return branchAccessDenied();
      }

      if (status === 503) {
        analytics.logErrorWithLevel("FullPageWarning", {
          code: 503,
        });

        return history.push("/server-unavailable");
      }

      if (status === 401 && !originalRequest.url.includes(publicRentPassportPart)) {
        analytics.logEvent("warningSessionExpired");
        unsetToken();
        unsetGhostId();
        unsetRefreshToken();

        return localStorage.removeItem("loginData");
      }

      if (typeof data === "string") {
        // 404
        return throwResponseError({message: locales.errors.somethingWentWrong, status});
      }

      const {errors = [], message: backendErrorMessage, type} = backendError;

      const mainMessage = getMainMessage(backendErrorMessage);
      const subErrors = formatSubErrors(errors);

      const message = `${mainMessage}${subErrors}`;

      return throwResponseError({message, status, type});
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      return throwResponseError({message: locales.errors.networkError, status: 0});
    }
    // Something happened in setting up the request and triggered an Error

    return Promise.reject(error);
  },
);

export const postFileWithProgress = async ({url, data, fileName, source, onProgress}) => {
  try {
    const res = await axios.post(url, data, {
      headers: {
        "Cache-Control": amazonRequestCacheOptions,
      },
      cancelToken: source.token,
      onUploadProgress: event =>
        onProgress(Math.round((event.loaded * 100) / event.total), fileName),
    });

    if (res.status !== 204) {
      throw new Error(res.status);
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error(abortRequestError);
    }
    throw new Error(error);
  }
};
